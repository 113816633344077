form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    

    input {

    }

    button {
        margin-top: 20px;
        width: 50px;
        height: 20px;
    }
    // flex-direction: column;
}