$yellow: #ffea65;
$yellowShadow: #f3d92e;
$black: black;
$red: #ec1212;
$brown: brown;

.pikachu {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 450px;
  border-radius: 40%;
  cursor: pointer;

  .ears {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 110%;
    top: 20px;

    .left,
    .right {
      position: relative;
      top: 10px;
      width: 35px;
      height: 100px;
      background-color: $yellow;
    }

    .left {
      animation: shakeEar 2s infinite;
      border-radius: 110px 160px 50px 160px / 400px 400px 240px 300px;
      transform: rotate(-25deg);
      transform-origin: bottom;
      left: 20px;

      &::before {
        transform: rotate(-1deg);
        position: absolute;
        width: 97%;
        height: 50px;
        content: "";
        left: 0;
        background-color: $black;
        border-radius: 200px 200px 0px 0px / 400px 400px 0px 0px;
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 40px;
        left: 0px;
        top: 20%;
        background-color: $yellow;
        border-radius: 80px 10px 0px 0px / 100px 70px 0px 0px;
      }
    }

    .right {
      border-radius: 160px 200px 160px 50px / 400px 600px 300px 240px;
      transform: rotate(25deg);

      &::before {
        transform: rotate(-1deg);
        position: absolute;
        width: 97%;
        height: 50px;
        content: "";
        right: 1px;
        background-color: $black;
        border-radius: 210px 200px 0px 400px / 400px 520px 0px 170px;
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 40px;
        left: 0px;
        top: 20%;
        background-color: $yellow;
        border-radius: 10px 80px 0px 0px / 20px 80px 0px 0px;
      }
    }
  }

  .face {
    z-index: 10;
    position: relative;
    width: 100%;
    height: 150px;
    background-color: #ffea65;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 70px 70px 50px 50px/ 80px 80px 40px 40px;
    box-shadow: 0px 1px 1px #00000060;
    box-sizing: border-box;
    border-top: 45px solid transparent;

    .eyes {
      position: relative;
      display: flex;
      align-items: center;
      width: 90%;
      padding-top: 3px;
      height: 40px;
      justify-content: space-around;

      .eye {
        position: relative;
        width: 40px;
        height: 40px;
        background-color: $black;
        border-radius: 100%;

        .pupil {
          width: 20px;
          height: 20px;
          position: absolute;
          border-radius: 100%;
          background-color: white;
        }
      }

      .left,
      .right {
        .pupil {
          left: 2px;
          top: 2px;
        }
      }
    }

    .nose {
      position: relative;
      width: 3px;
      height: 15px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 6px solid $black;
      border-radius: 10px 10px 20px 20px / 30px 30px 10px 10px;
    }

    .mouth {
      position: absolute;
      bottom: 20px;
      height: 34px;
      width: 40px;
      background-color: pink;
      content: "";
      box-shadow: 0px 0px 1px 1px #000000b8;
      background: radial-gradient(#ff7777 30%, $red 11%);
      border-radius: 0px 0px 30px 30px / 0px 0px 50px 50px;
      background-position: bottom 25%;
      background-size: 150px;
      background-position: -55px -23px;

      &::before {
        z-index: 100;
        content: "";
        position: absolute;
        right: 0px;
        top: -5px;
        width: 40px;
        height: 30px;
        background: radial-gradient($yellow 69%, transparent 11%);
        background-repeat: repeat-x;
        background-size: 20px 8px;
      }
    }

    .chicks {
      position: relative;
      left: 0.5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 94%;
      border-radius: 50%;
      bottom: 20px;

      .left,
      .right {
        background-color: $red;
        width: 35px;
        height: 45px;
        border-radius: 50%;
      }

      .left {
        transform: rotate(-30deg);
      }

      .right {
        transform: rotate(30deg);
      }
    }
  }

  .body {
    position: relative;
    box-shadow: inset 0px 0px 15px -1px #00000060;
    z-index: -1;
    bottom: 10px;
    background-color: $yellow;
    width: 60%;
    height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 40px 40px 40px 40px / 100px 100px 30px 30px;

    .arms {
      display: flex;
      justify-content: space-around;
      height: 60%;
      width: 100%;
      align-items: center;

      .left,
      .right {
        position: relative;
        content: "";
        height: 80%;
        width: 23%;
        background-color: $yellow;
      }

      .left {
        box-shadow: inset 1px -1px 3px 0px #00000060;
        border-radius: 30px 0px 20px 20px / 40px 0px 20px 20px;
        transform: rotate(-40deg);
      }

      .right {
        box-shadow: inset -1px -1px 3px 0px #00000060;
        border-radius: 0px 30px 20px 20px / 0px 40px 20px 20px;
        transform: rotate(40deg);
      }
    }
  }

  .legs {
    width: 100%;
    height: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: -10;

    .left,
    .right {
      position: relative;
      width: 45px;
      height: 150%;
      background-color: $yellow;
      border-radius: 40px 40px 40px 40px / 100px 100px 30px 30px;
      bottom: 15px;
      box-shadow: inset 0px 0px 15px -1px #00000060;
    }

    .left {
      transform: rotate(-2deg);
      border-radius: 400px 100px 500px 400px / 200px 100px 150px 200px;
    }

    .right {
      transform: rotate(2deg);
      border-radius: 100px 400px 400px 500px / 100px 200px 200px 200px;
    }
  }

  .tail {
    animation: shakeTail 1s infinite;
    transform: rotate(50deg);
    position: absolute;
    height: 200px;
    width: 100px;
    right: 43px;
    bottom: 104px;
    z-index: -2;
    transform-origin: bottom right;

    .tail-1 {
      height: 30px;
      z-index: 10;
      width: 45px;
      background-color: brown;
      position: absolute;
      bottom: 0;
      right: 0;
      box-shadow: 1px -3px 5px -1px #00000060;

      &::after {
        content: "";
        position: absolute;
        right: 12px;
        bottom: 9px;
        width: 30px;
        height: 35px;
        background-color: $brown;
      }

      &::before {
        content: "";
        position: absolute;
        right: 13px;
        bottom: 20px;
        width: 29px;
        height: 35px;
        box-shadow: 2px -4px 5px -1px #00000060;
        background-color: $yellowShadow;
      }
    }

    .tail-2 {
      box-shadow: 2px -2px 5px -1px #00000060;
      height: 20px;
      width: 45px;
      z-index: 10;
      position: absolute;
      bottom: 54px;
      right: 13px;
      border-radius: 0 3px 0 3px;
      background: $yellowShadow;

      &::after {
        content: "";
        position: absolute;
        right: 15px;
        bottom: 10px;
        width: 30px;
        height: 50px;
        background-color: $yellowShadow;
      }

      &::before {
        content: "";
        position: absolute;
        right: 15px;
        bottom: 33px;
        width: 49px;
        height: 31px;
        background-color: $yellowShadow;
      }
    }

    .tail-3 {
      height: 119px;
      width: 75px;
      z-index: 8;
      position: absolute;
      bottom: 110px;
      box-shadow: 3px 1px 5px -1px #00000060;
      right: 48px;
      border-radius: 3px 3px 0px 0px;
      background: $yellow;

      &::after {
        content: "";
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 45px;
        height: 100px;
        background-color: $yellowShadow;
      }

      &::before {
        content: "";
        position: absolute;
        right: 16px;
        transform: rotate(17deg);
        bottom: 0px;
        width: 43px;
        height: 112px;
        z-index: 1;
        background-color: $yellow;
      }
    }

    .tail-end {
      top: -43px;
      border-top: 15px solid transparent;
      border-left: 76px solid $yellow;
      content: "";
      z-index: 8;
      position: absolute;
      right: 47px;
    }
  }

  // &:hover {
  //   .tail {
  //   animation: shakeTail 1s infinite;
  // }

  //   .ears {
  //     .left {
  //       animation: shakeEar 2s infinite;
  //     }
  //   }
  // }
}

@keyframes shakeTail {
  0% {
    transform: rotate(55deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(55deg);
  }
}

@keyframes shakeEar {
  0% {
    transform: rotate(-25deg);
  }
  50% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(-25deg);
  }
}
